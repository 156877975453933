(function($) {
    "use strict"; // Start of use strict

    $ = jQuery;


    triggerHash();

    function triggerHash() {
        var url = window.location.href, idx = url.indexOf("#")
        var hash = idx != -1 ? url.substring(idx+1) : "";

        if (hash) {
            setTimeout(function() {
                $('[data-target*="'+ hash +'"]').trigger('click');
            }, 0);
        }
    }

    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Fermer (Esc)', // Alt text on close button
        tLoading: 'Chargement...', // Text that is displayed during loading. Can contain %curr% and %total% keys
        gallery: {
            tPrev: 'Précédente', // Alt text on left arrow
            tNext: 'Suivante', // Alt text on right arrow
            tCounter: '%curr% de %total%' // Markup for "1 of 7" counter
        }
    });

    $('[gallery_1]').magnificPopup({
        type: 'image',
        closeOnContentClick: false,
        gallery: {
            enabled: true
        }
    });



    // homepage slider
    $('.accueil-slider').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        fade: true
    });

    // Forms

    $('input, textarea').keyup(function() {
    	this.setAttribute('value', this.value);
    });

    $('.datepicker').pickadate({
        selectYears: true,
        selectMonths: true,
        selectYears: 40,
        max: new Date(),
        monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        format: 'dd mmmm yyyy',
        formatSubmit: 'yyyy-mm-dd',
        today: 'Aujourd\'hui',
        clear: 'Réinitialiser',
        close: 'Fermer'
    });

    $('[data-parsley-icon]').length && $('[data-parsley-icon]').parsley({
    	trigger:      'change',
    	successClass: 'parsley-success',
    	errorClass: 'parsley-error',
    	classHandler: function (el) {
    		return el.$element;
    	},
    	errorsContainer: function(pEle) {
    		var $err = pEle.$element.closest('.form-item--wide').append();
    		return $err;
    	}
    });

    $('#input-multi').MultiFile(3);

    $('#input-file').on('click', function() {
        $('.input-multi:first').trigger('click');
    });

    $('#contact, #referring').on('submit', function(e) {
        var $form = $(this);

        $.ajax({
            type: 'POST',
            url: $form.find('input[name="action"]').val(),
            data: $(this).serialize(),
            dataType: 'json',
            success: function(data) {
                var $recaptchaError = $form.find('.recaptcha-error');
                $recaptchaError.css('display', 'none');

                if (data.success) {
                    $('.form--contact').addClass('is-sent');
                    $('.form--referring').addClass('is-sent');
                }

                if (data.recaptchaError) {
                    $form.find('.recaptcha-error').css('display', 'block');
                    $('.btn--primary').prop("disabled", false);
                }
            }
        });

        e.preventDefault();
    });

    $('#new-message').on('click', function(e) {
        e.preventDefault();
        $('.form--contact').removeClass('is-sent');
        $('.btn--primary').prop("disabled", false);
        if (grecaptcha) {
            grecaptcha.reset();
        }
    });

    $('#new-referring').on('click', function(e) {
        e.preventDefault();
        $('.form--referring').removeClass('is-sent');
    });

    $('#referring,#contact').submit(function(){
        $('.btn--primary').prop( "disabled", true );
    });

    // Tabs

    $('.tab__head > div').on('click', function() {
        var $this = $(this);

        $($this.data('target'))
            .addClass('is-active')
            .siblings()
            .removeClass('is-active');

        $this
            .addClass('is-active')
            .siblings()
            .removeClass('is-active');
    });

    // Google map

    if ( $('#map').length ) {
        //var styleArray = [{
        //    featureType: 'all',
        //    stylers: [
        //        { saturation: -80 }
        //    ]
        //},{
        //    featureType: 'road.highway',
        //    elementType: 'geometry.fill',
        //    stylers: [
        //        { color: "#BAC6BF" }
        //    ]
        //},{
        //    featureType: 'poi.business',
        //    elementType: 'labels',
        //    stylers: [
        //        { visibility: 'off' }
        //    ]
        //}];

        GMap.init({
            map_id: 'map',
            api_key: 'AIzaSyCjV8WLioEBkeTQmcrKD81nwBnW4GKT1Co',
            map_options: {
                zoom: 15,
                zoomControl: true,
                scrollwheel: false
            }
        });
    }

    $('[data-image]:not(.is-toggled)').click(function(){
        var image = "/dist/images/" + $(this).data('image') + ".jpg";
        $('#featured-img').attr('src', image);
    });

    $('[data-href]').click(function () {
        window.location.href = $(this).attr('data-href');
    });


})(jQuery); // End of use strict
