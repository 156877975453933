var Toggables = (function(window, document) {

    'use strict';

    var
    triggers,
    trgets,
    options = {},
    defaults = {
        toggled_class: 'is-toggled',
        trigger_selector: '.toggable-trigger',
        target_selector: '.toggable-target',
        on_toggled: false,
        off_toggled: false
    };

    var _setOptions = (config) => {
        if ( typeof config === 'object') {
            for ( var key in defaults ) {
                if (!defaults.hasOwnProperty(key)) {
                    continue;
                }
                options[key] = (typeof config[key] === 'undefined' ? defaults[key] : config[key]);
            }
        }
    };

    var _openTrget = (trigger, trget) => {
        for (let i = 0; i < triggers.length; i++) {
            _closeTrget(triggers[i], trgets[i]);
        }
        trigger.classList.add(options.toggled_class);
        trigger.toggled = true;
        if ( typeof options.on_toggled === 'function' ) 
            options.on_toggled(trigger, trget);
    };

    var _closeTrget = (trigger, trget) => {
        trigger.classList.remove(options.toggled_class);
        trigger.toggled = false;
        if ( typeof options.off_toggled === 'function' ) 
            options.off_toggled(trigger, trget);
    };

    var _toggleTrget = (trigger, trget) => {
        trigger.toggled ? _closeTrget(trigger, trget) : _openTrget(trigger, trget);
    };

    var _getElements = () => {
        triggers = document.querySelectorAll(options.trigger_selector);
        trgets = document.querySelectorAll(options.target_selector);
    };

    var _bindListeners = () => {
        var len = triggers.length === trgets.length ? triggers.length : false;

        for (let i = 0; i < len; i++) {
            triggers[i].toggled = false;
            triggers[i].addEventListener( 'click', function() {
                _toggleTrget(this, trgets[i]);
            });
        }
    };

    var init = (config) => {
        _setOptions(config);
        _getElements();
        _bindListeners();
    };

    var publicAPI = {
        init: init
    };

    return publicAPI;

})(window, document);

Toggables.init({
    on_toggled: (trigger, trget) => { trget.style.height = trget.scrollHeight + 'px'; },
    off_toggled: (trigger, trget) => { trget.style.height = 0; }
});