var GMap = (function(window, undefined) {

	'use strict';

	var
		map_obj = null,
		map_el = null,
		marker = null,
		markers = [],
        bounds = null,
		lat_lng = {},
		options = {},
		defaults = {
			map_id: 'map',
			api_key: 'ADD-ME',
			init_class: 'map-loaded',
			map_options: {}
		};

	function init(config) {
		_setOptions(config);
		_getElements();
		if (map_el && map_el.getAttribute('data-maplat') && map_el.getAttribute('data-maplng')) {
			_createEvents();
			_getGoogleMapsAPI();
			_getLocation();
			_bindListeners();
		} else {
            console.warn('You need data attributes');
			return;
		}
	}

	function _setOptions(config) {
		if ( typeof config === 'object') {
			for ( var key in defaults ) {
				if ( !defaults.hasOwnProperty(key) ) {
					continue;
				}
				options[key] = (typeof config[key] === 'undefined' ? defaults[key] : config[key]);
			}
	    }
	}

	function _getElements() {
		map_el = document.getElementById(options.map_id);
	}

	function _createEvents() {
		window.googleMapsInitiated = function() {
			var event = document.createEvent('CustomEvent');
			event.initCustomEvent('gmap-init', true, true, {});
			document.dispatchEvent(event);
		};
	}

	function _bindListeners() {
		document.addEventListener('gmap-init', _initMap);
	}

	function _getGoogleMapsAPI() {
		var
			body = document.querySelector('body'),
			script_gmap = document.createElement('script');

		script_gmap.type = 'text/javascript';
		script_gmap.src = 'https://maps.googleapis.com/maps/api/js?key=' + options.api_key + '&callback=googleMapsInitiated';

		body.appendChild(script_gmap);
	}

	function _getLocation() {
		lat_lng.lat = parseFloat(map_el.getAttribute('data-maplat')) || 0;
		lat_lng.lng = parseFloat(map_el.getAttribute('data-maplng')) || 0;
	}

	function _addMarker(location, mark_title, mark_icon) {
		marker = new google.maps.Marker({
			position: location,
			map: map_obj,
			title: mark_title,
			icon: mark_icon
		});

		markers.push(marker);
		bounds.extend(marker.position);
	}

	function _setMapOnAll(map) {
		for (var i = 0; i < markers.length; i++) {
			markers[i].setMap(map);
		}
	}

	function _clearMarkers() {
		_setMapOnAll(null);
	}

	function _showMarkers() {
		_setMapOnAll(map_obj);
	}

	function _deleteMarkers() {
  		_clearMarkers();
  		markers = [];
		bounds = new google.maps.LatLngBounds();
	}

	function _initMap() {
		map_obj = new google.maps.Map(map_el, {});
        bounds = new google.maps.LatLngBounds();

        var mark_office = new google.maps.MarkerImage(
            '/dist/images/pin-office.png',
            new google.maps.Size(53,60),    // size of the image
            new google.maps.Point(0,0), // origin, in this case top-left corner
            new google.maps.Point(26, 60)    // anchor, i.e. the point half-way along the bottom of the image
        );

        var mark_proximity = new google.maps.MarkerImage(
            '/dist/images/pin-proximity.png',
            new google.maps.Size(31,42),    // size of the image
            new google.maps.Point(0,0), // origin, in this case top-left corner
            new google.maps.Point(18, 42)    // anchor, i.e. the point half-way along the bottom of the image
        );

		_addMarker(lat_lng, map_el.getAttribute('data-maptitle'), mark_office);

        var marker_lnk = document.querySelectorAll('.map-pin'),
            loc = {};

        for (var i = 0; i < marker_lnk.length; i++) {
            marker_lnk[i].addEventListener('click', function() {
                loc.lat = parseFloat(this.getAttribute('data-maplat'));
        		loc.lng = parseFloat(this.getAttribute('data-maplng'));

				_deleteMarkers();

				_addMarker(lat_lng, map_el.getAttribute('data-maptitle'), mark_office);
				_addMarker(loc, this.getAttribute('title'), mark_proximity);

                map_obj.fitBounds(bounds);

				if (window.outerWidth < 640) {
					location.href = "#map";
				}
            });
        }

		map_obj.setOptions({center: lat_lng});
		map_obj.setOptions(options.map_options);
		map_el.classList.add(options.init_class);
	}

	var public_API = {
		init: init
	};

	return public_API;

})(window, undefined);
